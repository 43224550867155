'use strict';

var cart = require('../cart/cart');
var wishlist = require('../product/wishlist');

var updateMiniCart = true;

function getEstimatedDeliveryDateMiniCart() {
    var $eddElem = $('.estimateDeliveryDateDataMC');
    // TODO add loop eddElem
    if ($eddElem.length) {
        $eddElem.each(function (e) {
            var $this = $(this);
            var url = $this.data('url');
            var val = $this.val();
            var uuid = $this.data('uuid');
            var $eddText = $this.closest('.js-edd-mc-container.uuid-' + uuid);
            var $eddPidDiv = $eddText.find('.js-edd-request.uuid-' + uuid);
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                data: { eddObj: val },
                success: function (data) {
                    if (data.success) {
                        var serviceCode;
                        var carrierCode;
                        try {
                            serviceCode = JSON.parse(val).serviceCode;
                            carrierCode = JSON.parse(val).carrierCode;
                        } catch (e) {
                            serviceCode = 'FG';
                        }
                        $eddPidDiv
                            .text('')
                            .attr('data-narvar-service-code', serviceCode)
                            .attr('data-narvar-carrier-code', carrierCode);
                        if (window.narvar) {
                            window.narvar('getEdd', {});
                            var limit = 0;
                            var timeInt;
                            var unHideEdd = function () {
                                if ($eddPidDiv.text().length > 0 || limit > 100) {
                                    if ($eddPidDiv.text().length > 0) {
                                        $eddText.removeClass('hide');
                                        $eddPidDiv.closest('.eddtext.hide').removeClass('hide');
                                    }
                                    clearTimeout(timeInt);
                                } else {
                                    timeInt = setTimeout(unHideEdd, 100);
                                }
                            };
                            unHideEdd();
                        }
                    }
                }
            });
        });
    }
}

module.exports = function () {
    cart();

    $('.minicart').on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart .minicart-quantity').text(count.quantityTotal);
            $('.minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems
            });
        }
    });

    $('.minicart').on('mouseenter focusin touchstart', function (e) {
        if ($(window).width() >= 1024) {
            if ($('.search:visible').length === 0) {
                return;
            }
            if ($('.minicart .popover.d-block').length !== 0) {
                return;
            }
            var url = $('.minicart').data('action-url');
            var count = parseInt($('.minicart .minicart-quantity').text(), 10);

            if (count !== 0 && $('.minicart .popover.show').length === 0) {
                if (!updateMiniCart) {
                    $('.minicart .popover').addClass('show');
                    $('.minicart-link').attr('aria-expanded', 'true');
                    return;
                }

                $('.minicart .popover').addClass('show');
                $('.minicart-link').attr('aria-expanded', 'true');

                $('.minicart .popover')
                    .spinner()
                    .start();
                $.get(url, function (data) {
                    $('.minicart .popover').empty();
                    $('.minicart .popover').append(data);
                    updateMiniCart = false;
                    // update EDD
                    getEstimatedDeliveryDateMiniCart();
                    $.spinner().stop();
                });
            }
        }
    });
    $('body').on('keydown', '.popover-bottom', function (e) {
        if (e.key === 'Escape') {
            $('.minicart .popover').removeClass('show');
            $('.minicart-link').attr('aria-expanded', 'false');
        }
    });
    $('body').on('touchstart click', function (e) {
        if ($('.minicart').has(e.target).length <= 0) {
            $('.minicart .popover').removeClass('show');
            $('.minicart-link').attr('aria-expanded', 'false');
        }
    });
    $('.minicart').on('mouseleave focusout', function (event) {
        if (
            (event.type === 'focusout' && $('.minicart').has(event.target).length > 0) ||
            (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity')) ||
            $('body').hasClass('modal-open')
        ) {
            event.stopPropagation();
            return;
        }
        $('.minicart .popover').removeClass('show');
        $('.minicart-link').attr('aria-expanded', 'false');
    });

    $(document).on('click', '.close-minicart-btn, .minicart-popover-modal', function () {
        $('.minicart .popover').removeClass('show');
        $('.minicart .popover').removeClass('d-block');
        $('.minicart-link').attr('aria-expanded', 'false');
        var $miniCartTitle = $('.mini-cart-title');
        $miniCartTitle.text($miniCartTitle.attr('data-default-title'));
        $('.icon-added-to-bag-wrapper').addClass('d-none');
        $('.icon-tote-wrapper').removeClass('d-none');
    });

    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
    });
    $('body').on('click', function (e) {
        if (!$(e.target).parents('.minicart').length && !$(e.target).hasClass('minicart') && !$(e.target).parents('.modal').length) {
            $('.minicart .popover').removeClass('d-block');
            var $miniCartTitle = $('.mini-cart-title');
            $miniCartTitle.text($miniCartTitle.attr('data-default-title'));
            $('.icon-added-to-bag-wrapper').addClass('d-none');
            $('.icon-tote-wrapper').removeClass('d-none');
        }
    });
    $('body').on('product:afterAddToCart', function (e, eventData) {
        updateMiniCart = true;
        var url = $('.minicart').data('action-url');
        var count = parseInt($('.minicart .minicart-quantity').text(), 10);

        if (count !== 0 && $('.minicart .popover.show').length === 0) {
            if (!updateMiniCart) {
                $('.minicart .popover').addClass('show');
                $('.minicart-link').attr('aria-expanded', 'true');
                return;
            }

            $('.minicart .popover').addClass('show');
            $('.minicart-link').attr('aria-expanded', 'true');

            $('.minicart .popover')
                .spinner()
                .start();
            $.get(url, function (data) {
                $('.minicart .popover').empty();
                $('.minicart .popover').append(data);
                updateMiniCart = false;
                // update EDD
                getEstimatedDeliveryDateMiniCart();
                $('.minicart .popover').addClass('d-block');
                var $miniCartTitle = $('.mini-cart-title');
                $miniCartTitle.text($miniCartTitle.attr('data-product-added-title'));
                $('.products-row').animate(
                    {
                        scrollTop: $('.products-row').scrollTop() + $('.product-row[data-pid="' + eventData.addedPid + '"]').position().top
                    },
                    1000
                );
                $('.icon-added-to-bag-wrapper').removeClass('d-none');
                $('.icon-tote-wrapper').addClass('d-none');
                $('.minicart-popover-modal').addClass('modal-zindex-high');
                $.spinner().stop();
            });
        }
    });
    $('body').on('cart:update', function () {
        if ($('.minicart .popover.d-block').length !== 0) {
            return;
        }
        updateMiniCart = true;
        // customization code to update promtion messages on cart update
        var url = $('.minicart').data('action-url');
        $.get(url, function (data) {
            $('.minicart .popover').empty();
            $('.minicart .popover').append(data);
            // update EDD
            getEstimatedDeliveryDateMiniCart();
            $.spinner().stop();
        });
    });
    $('body').on('initRecommendationSlider', function () {
        var $minicartRecommender = $('.minicart .popover .minicart-recommender__slider');
        $minicartRecommender.slick({
            arrows: true,
            slidesToShow: 3,
            dots: false,
            infinite: false
        });
        wishlist.initWishlistItems();
    });
};
